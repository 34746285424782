<template>
  
  <div>
    
    <v-row justify="center" class="">
      <v-col>
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Немає даних для відображення"
          :server-items-length="paginations.count" 
          :page.sync="page"
          :items="messages"
          :options.sync="options"
          :footer-props="footer"
          :loading="loading"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          class="elevation-1 mt-5 row-cursor "
          focusable
          disable-sort
          @click:row="onClickRow"
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-1" dark >
              <v-toolbar-title class=""><h2 class="mr-6 accent--text">Розсилки</h2></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ messages.indexOf(item) + paginations.start_index }}</span>
        </template>
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
          <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      </v-row>
      <keep-alive>
        <MessagesDetailsModal ref="messagesDetailsModal" />
      </keep-alive>
  </div>
</template>

<script>
  import axios from "axios"
  import MessagesDetailsModal from '@/components/Messages/MessagesDetailsModal.vue'
  export default {
    //props: ['abonent'],
    name: "table-messages",
    components:{
      MessagesDetailsModal
    },
    data: () => ({
      updateTable: 0,
      messages:  [],
      loading: false,
      options: {},
      page: 1,
      paginations: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Дата', value: 'date_create',  },
        { text: 'Назва', value: 'name', sortable: false, },
        { text: "Всього", value: 'count_messages', cellClass: 'text-end', class: 'text-end', },
        { text: "Відправлено", value: 'count_sended', cellClass: 'text-end', class: 'text-end', },
        { text: "Viber", value: 'count_viber', cellClass: 'text-end', class: 'text-end', },
        //{ text: "Дата статусу", value: 'date_status' },
        
        
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    watch: {
      options: {
				handler (val, oldVal) {
					oldVal.page && this.getMessages();
				},
				deep: true,
			},
    },
    mounted() {
     this.checkGetMessages()
   },
    methods: {
      onClickRow(item){
        this.$refs.messagesDetailsModal.urlId = item.id
        this.$refs.messagesDetailsModal.dialog = true
      },
      checkGetMessages(){
        if(this.options.page == 1){
          this.getMessages()
        }else{
          this.options.page = 1;
        }
      },
      filterFormat() {
        const temp = {};
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        return temp; 
      },
      getMessages(){
        this.loading = true;
        axios.get(`${this.$router.app.devUrlServiceSms}/api_messages/get_bulks/`, {params: this.filterFormat()}) // this.$route.params.id
        .then( response =>{
          //console.log(response.data)
          this.messages = response.data.results
          this.paginations = response.data.paginations
        })
        .catch(() => {
          //console.log(error);
          //this.errored = true;
        })
        .finally(() => (this.loading = false))
        
      }
    },
  }
</script>

  
